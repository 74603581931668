$primary-color: #2b4250;
$secondary-color: #1ea548;

$header-height: 70px;

$contact-block-icon-size: 120px;

$branch-address-icon-size: 24px;

$image-separator-image: 'https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9';
$service-separator-image: '/assets/images/oildrop-truck.jpg';

$contact-form-header-height: 150px;