@import 'assets/scss/config/_variables.scss';

#partners {
    padding: 2rem 0.75rem;
    background-color: $secondary-color;

    .container {
        overflow: hidden;
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: scroll;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            min-width: 100%;
            padding: 0 15%;
            scroll-snap-align: center;
            text-align: center;

            svg {
                max-height: 60px;
                fill: whitesmoke;
            }

            #kfc {
                max-width: 120px;
            }

            #nandos {
                max-width: 154px;
            }
        }
    }
}

@media(min-width: 768px) {
    #partners {
        ul {
            li {
                min-width: 16%;
                max-width: 16%;
                padding: 0;
            }
        }
    }
}