#service-icons {
    background-color: #f7f7f7;
    padding: 4rem 0.75rem;

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;
    }

    img {
        height: 80px;
        margin-bottom: 1rem;
    }
}

@media(min-width: 1024px) {
    #service-icons {
        .column {
            margin-bottom: 0;
        }
    }
}