@import 'assets/scss/config/_variables.scss';

#our-services {
    padding: 6rem 0.75rem;

    .section-title {
        color: $primary-color;
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;

        .byline {
            max-width: 640px;
            text-align: center;
        }
    }

    .service-grid {
        text-align: center;
        margin-top: 50px;

        .service {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding: 60px 30px;

            .image {
                margin-bottom: 20px;

                img {
                    height: 100%;
                    width: auto;
                }
            }

            h3 {
                font-size: 1.3em;
                font-weight: 700;
                color: $primary-color;
                margin-bottom: 20px;
            }

            &:before {
                content: ' ';
                position: absolute;
                width: 60%;
                height: 1px;
                background-color: #efefef;
                top: 0;
                left: 20%;
            }

            &:after {
                content: ' ';
                position: absolute;
                height: 40%;
                width: 1px;
                background-color: #efefef;
                top: 30%;
                right: 0;
            }

            &:nth-child(3n) {
                &:after {
                    content: none;
                }
            }

            &:nth-child(-n+3) {
                &:before {
                    content: none;
                }
            }
        }
    }
}