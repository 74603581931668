#booking {
    .container {
        padding: 4rem 0.75rem;
    }
}

@media(min-width: 1024px) {
    #booking {
        .container {
            padding: 4rem 0;
        }
    }
}