@import 'assets/scss/config/_variables.scss';

@mixin icon-ring($baseColor) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5em;
    position: absolute;
    background-color: $baseColor;
    border-style: solid;
    border-color: lighten($baseColor, 20%);
}

@mixin icon-position($size) {
    height: $size;
    width: $size;
    border-radius: ($size / 2);
    top: -($size / 2);
}

#contact-blocks {
    height: 240px + ($contact-block-icon-size / 2);
    width: 100%;
    color: #fff;
    margin: 0 -0.75rem;
    overflow-y: hidden;

    .container {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding-top: $contact-block-icon-size / 2;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    h3 {
        font-size: 2em;
        font-weight: 700;
        margin-bottom: 7px;
    }

    .text {
        font-size: .9em;
        strong {
            color: inherit;
            font-weight: 700;
            margin-bottom: 7px;
        }
    }

    .cblock {
        min-width: 100%;
        position: relative;
        scroll-snap-align: center;
    }

    .contact {
        height: 90%;
        background-color: lighten($secondary-color, 5%);
        border-radius: 5px;
        padding-top: 68px;
        text-align: center;

        .icon {
            @include icon-ring(lighten($secondary-color, 5%));
            @include icon-position($contact-block-icon-size * .8);
            border-width: 3px;
            left: calc(50% - 48px);
        }

        .text {
            a {
                color: #fff;

                &:hover {
                    color: #ccc;
                }
            }
        }
    }

    .address {
        height: 100%;
        background-color: $secondary-color;
        border-radius: 5px;
        padding-top: 80px;
        text-align: center;

        .icon {
            @include icon-ring($secondary-color);
            @include icon-position($contact-block-icon-size);
            border-width: 5px;
            left: calc(50% - 60px);
        }
    }

    .hours {
        height: 90%;
        background-color: darken($secondary-color, 5%);
        border-radius: 5px;
        padding-top: 68px;
        text-align: center;

        .icon {
            @include icon-ring(darken($secondary-color, 5%));
            @include icon-position($contact-block-icon-size * .8);
            border-width: 3px;
            left: calc(50% - 48px);
        }

    }
}

@media(min-width: 768px) {
    #contact-blocks {
        .cblock {
            min-width: 33.3%;
            max-width: 33.3%;
        }

        .contact {
            border-radius: 5px 0 0 5px;
        }

        .hours {
            border-radius: 0 5px 5px 0;
        }
    }
}