@import 'assets/scss/config/_variables';

.blog-post {
    .image {
        max-height: 200px;
        overflow: hidden;

        img {
            transition: transform .2s;
        }

        &:hover > img {
            transform: scale(1.2);
        }
    }

    .title {
        transition: color .2s;

        h3 {
            font-weight: 700;
            line-height: 3rem;
        }

        &:hover {
            color: $secondary-color;
        }
    }

    .date {
        color: #ccc;
        font-size: .9em;
        font-weight: 700;
    }

    p {
        margin-bottom: .9rem;
    }

    .more {
        color: $secondary-color;
        font-size: .9em;
        
        &:hover {
            color: $secondary-color;
            text-decoration: underline;
        }
    }

    hr {
        margin: 2rem 0;
    }
}