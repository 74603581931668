@import 'assets/scss/config/_variables';

#booking-steps {
    ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    li {
        display: flex;
        align-items: center;
        position: relative;
        width: calc(33.3% - 10px);

        .text-wrap {
            display: none;
        }

        .icon {
            width: 32px;
            height: 32px;
            border: 1px solid #ccc;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: #ccc;
            }
        }

        &:last-child {
            width: auto;
        }

        &:not(:last-child) {
            .icon {
                &:after {
                    content: '';
                    position: absolute;
                    background-color: #ccc;
                    height: 3px;
                    width: calc(100% - 68px);
                    left: 48px;
                }
            }
        }

        &.current {
            .icon {
                border-color: $primary-color;
                background-color: $primary-color;

                svg {
                    color: #fff;
                }
            }

            .text-wrap {
                p {
                    color: #9a9a9a;
                }

                h3 {
                    color: #4a4a4a;
                }
            }
        }

        &.completed {
            .icon {
                background-color: $secondary-color;
                border-color: $secondary-color;

                &:after {
                    background-color: $secondary-color;
                }

                svg {
                    color: #fff;
                }
            }

            .text-wrap {
                p {
                    color: #9a9a9a;
                }

                h3 {
                    color: #4a4a4a;
                }
            }
        }
    }
}

@media(min-width: 768px) {
    #booking-steps {
        li {
            width: 25%;

            .icon {
                flex-shrink: 0;
            }

            .text-wrap {
                display: block;
                padding: 0 1rem;

                p {
                    font-size: 0.5em;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #eaeaea;
                }

                h3 {
                    font-size: 0.75rem;
                    font-weight: 700;
                    color: #ccc;
                }
            }

            &:not(:last-child) {
                .icon {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}

@media(min-width: 1024px) {
    #booking-steps {
        ul {
            display: block;
            margin-bottom: 0;
        }

        li {
            padding: 1rem 0 2rem;
            width: 100%;

            .text-wrap {
                display: block;
                padding: 0;

                p {
                    font-size: .6em;
                }
                
                h3 {
                    font-size: 1em;
                }
            }

            .icon {
                margin-right: 2rem;
            }

            &:not(:last-child) {
                .icon {
                    &:after {
                        content: '';
                        position: absolute;
                        background-color: #ccc;
                        width: 3px;
                        height: 35%;
                        left: initial;
                        bottom: 0;
                    }
                }
            }
        }
    }
}