#booking-details-form {
    padding: 0.8rem 0;

    .column {
        position: relative;
    }

    .form-error {
        color: red;
        font-size: .8em;
        position: absolute;
        right: 1rem;
        bottom: 0.75rem;
    }
}