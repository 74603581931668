@import 'assets/scss/config/_variables';

#page-header {
    background-color: $primary-color;
    background: linear-gradient($primary-color, darken($primary-color, 5%));
    padding: 8rem 0.75rem;
    color: #fff;
    text-align: center;

    h2 {
        font-size: 2.5em;
        font-weight: 700;
    }

    h5 {
        font-size: 1.25em;
    }
}

@media(min-width: 1024px) {
    #page-header {
        text-align: left;
    }
}