@import 'assets/scss/config/_variables.scss';

#footer {
    .main {
        padding: 4rem 0.75rem;
        background-color: $primary-color;
        color: #fff;
        text-align: center;

        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 2rem;

            img {
                max-width: 160px;
                margin-bottom: 1.5rem;
            }
        }

        h3 {
            font-size: 1.7em;
            font-weight: 700;
            margin-bottom: 40px;
            text-align: center;
        }

        .links {
            li {
                display: inline-block;
                width: 100%;
                font-size: 1em;
                font-weight: 600;
                margin-bottom: 14px;
                padding-left: 12px;
                position: relative;

                &:before {
                    content: none;
                    position: absolute;
                    width: 3px;
                    height: 3px;
                    background-color: $secondary-color;
                    left: 0;
                    top: calc(50% - 1.5px);   
                }

                a {
                    color: #fff;

                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
        }

        .button {
            margin-top: 25px;
        }
    }
    
    .meta {
        background-color: darken($primary-color, 3%);
        color: #aaa;
        font-size: .7em;
        padding: 0.75rem;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        p {
            margin-bottom: 0.25rem;
        }

        .plug {
            span {
                margin: 0 0.4rem 0 0.25rem;
            }
        }
    }
}

@media(min-width: 1024px) {
    #footer {
        .main {
            text-align: left;

            .logo {
                align-items: flex-start;
                margin-bottom: 0;
            }

            .links {
                li {
                    width: 50%;

                    &:before {
                        content: '';
                    }
                }
            }

            h3 {
                text-align: left;
            }
        }

        .meta {
            .container {
                flex-direction: row;
                justify-content: space-between;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}