@import 'assets/scss/config/_variables';

#blog-post {
    .post-header {
        background-color: $primary-color;
        color: #fff;
        padding: 8rem 0.75rem;

        h2 {
            font-size: 2.5em;
            font-weight: 700;
        }

        h5 {
            line-height: 1.75em;
        }
    }

    .content {
        padding: 4.75rem 0.75rem;

        p {
            white-space: break-spaces;
        }
    }
}