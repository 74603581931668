@import 'assets/scss/config/_variables';

#branch {
    .branch-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e7e7e7;
        padding: 1rem 0.5rem;
        cursor: pointer;

        .title {
            margin-bottom: 0;
        }

        svg {
            font-size: 1.2em;
        }
    }

    .branch-content {
        padding: 1rem 0.5rem;

        li {
            margin-bottom: 0.5rem;
            font-size: 0.9em;
        }

        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: $branch-address-icon-size;
            height: $branch-address-icon-size;
            background-color: $secondary-color;
            border-radius: $branch-address-icon-size / 2;
            margin-right: 10px;

            svg {
                color: #fff;
                font-size: 0.8em;
            }
        }
    }
}