#social-icons {
    display: flex;
    justify-content: center;

     li {
        margin: 0 1rem;
     }
}

@media(min-width: 1024px) {
   #social-icons {
      justify-content: flex-start;

      li {
         margin: 0 2rem 0 0;

         &:last-child {
            margin: 0;
         }
      }
   }
}