#booking-date-form {
    .input.is-hidden {
        display: none;
    }

    .title {
        margin: 0.5rem 0 0.75rem;
    }

    p {
        margin-bottom: 1.5rem;
    }

    label.checkbox {
        padding-left: 32px;
        position: relative;

        input[type="checkbox"] {
            display: none;

            &:checked ~ .check {
                &:before {
                    content:"";
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background-color:green;
                    left: 11px;
                    top: 3px;
                    transform: rotate(40deg);
                }

                &:after {
                    content:"";
                    position: absolute;
                    width: 4px;
                    height: 8px;
                    background-color:green;
                    left: 5px;
                    top: 10px;
                    transform: rotate(-50deg);
                }
            }
        }

        .check {
            width: 24px;
            height: 24px;
            border: 1px solid #4a4a4a;
            border-radius: 3px;
            position: absolute;
            left: 0;
        }
    }
}