@import 'assets/scss/config/_variables';

#image-separator {
    height: calc(100vw);
    background-color: $primary-color;
    background: linear-gradient(rgba($primary-color, .5), rgba($primary-color, .5)), url($image-separator-image);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrap {
        color: #fff;
        font-size: 2.4em;
        font-weight: 700;
        max-width: 800px;
        text-align: center;
    }
}

@media(min-width: 1024px) {
    #image-separator {
        height: calc(46vw);
    }
}