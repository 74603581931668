@import 'assets/scss/config/_variables.scss';

#who-we-are {
    padding: 220px 0.75rem 6rem;

    .image {
        display: flex;
        justify-content: center;

        img {
            width: auto;
        }
    }
    
    .section-title {
        color: $primary-color;
    }

    p {
        margin-bottom: 1rem;
    
        &> strong {
            margin: 15px 0;
        }
    }

    .emphasize {
        font-weight: 600;
        margin: 20px 0;

        .secondary {
            color: $secondary-color;
        }
    }

    .button {
        margin-top: 30px;
    }
}