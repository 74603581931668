@import 'assets/scss/config/_variables.scss';

#masthead {
    padding: 7.5rem 0.75rem 270px;
    background-color: $primary-color;
    color: #fff;
    position: relative;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .byline {
        font-size: 1.2em;
        max-width: 640px;
        text-align: center;
        margin-bottom: 50px;
    }

    #contact-blocks {
        position: absolute;
        bottom: -120px;
    }
}