#brands {
    padding: 3rem 0.75rem;

    .section-title {
        font-size: 1.4em;
        margin-bottom: 2.4rem;
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: scroll;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            min-width: 100%;
            padding: 0 5%;
            scroll-snap-align: center;
            text-align: center;

            svg {
                max-height: 60px;
                fill: #acacac;
            }

            #kfc {
                max-width: 120px;
            }

            #nandos {
                max-width: 154px;
            }
        }
    }
}

@media(min-width: 768px) {
    #brands {
        ul {
            li {
                padding: 0;
                min-width: 16%;
                max-width: 16%;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}