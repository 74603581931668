@import 'assets/scss/config/_variables';

#call-to-action {
    background-color: $primary-color;
    background: linear-gradient(darken($primary-color, 5%), lighten($primary-color, 5%));
    padding: 6rem 0.75rem;
    
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    img {
        max-width: 160px;
        margin-bottom: 3.5rem;
    }

    .title, p {
        color: #fff;
    }

    .title {
        margin-bottom: 1.2rem;
        text-align: center;
    }

    .button {
        margin-top: 3rem;
        font-size: 1em;
        font-weight: 600;
        text-transform: initial;
    }
}