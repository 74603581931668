#service-columns {
    padding: 6.75rem 0.75rem;

    .section-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .subline {
        text-align: center;
        margin-bottom: 4rem;
        display: flex;
        justify-content: center;

        p {
            max-width: 900px;
        }
    }

    .service {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        .image {
            height: 240px;
            border-radius: 7px;
            margin-bottom: 2rem;
            overflow: hidden;

            img {
                min-height: 100%;
                min-width: 100%;
            }
        }

        p {
            text-align: center;
        }
    }
}

@media(min-width: 1024px) {
    #service-columns {
        .service {
            margin-bottom: 0;
        }
    }
}