@import 'assets/scss/config/_variables.scss';

#posts {
    padding: 6rem 0.75rem;

    .section-title {
        color: $primary-color;
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .byline {
            max-width: 640px;
            text-align: center;
        }
    }
}