@import 'assets/scss/config/_variables.scss';

#header {
    height: $header-height;

    .main {
        background-color: $primary-color;
        height: $header-height;
        width:100%;
        position: fixed;
        padding: 10px 0.75rem;
        z-index: 5;

        .container {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .logo {
            margin-right: 2rem;

            a {
                display: block;
                height: $header-height * 0.6;
            }

            img {
                height: 100%;
                max-width: 160px;
            }
        }

        .main-menu {
            display: none;
            align-items: center;
            font-size: 1.1em;
            font-weight: 300;

            li {
                position: relative;
                padding-left: 30px;
                margin-right: 30px;

                a {
                    color: #fff;

                    &:hover {
                        color: $secondary-color;
                    }
                }

                &:before {
                    content: " ";
                    position: absolute;
                    height: 4px;
                    width: 4px;
                    background-color: $secondary-color;
                    top: calc(50% - 2px);
                    left: 0px;
                }

                &:first-child {
                    &:before {
                        content: none;
                    }
                }
            }
        }

        .navbar-burger {
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 5px;
            height: $header-height * 0.6;
            width: $header-height * 0.6;

            &:focus {
                outline-color: $secondary-color;
            }

            span {
                color: #fff;
            }
        }

        a.button {
            display: none;
            transition: all ease 0.35s;

            &:hover {
                background-color: $secondary-color;
                border-color: $secondary-color;
                color: #fff;
            }
        }
    }

    .mobile-menu {
        position: fixed;
        top: $header-height;
        width: 100%;
        background-color: $primary-color;
        border-bottom: 4px solid $secondary-color;
        z-index: 5;

        li {
            padding: 0.75rem 0.75rem;
            border-bottom: 1px solid lighten($primary-color, 5%);
            
            button {
                background-color: transparent;
                border: none;
                color: #fff;
                font-size: 1.2em;
                text-align: left;
                width: 100%;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

@media(min-width: 1024px) {
    #header {
        .main {
            .main-menu, 
            a.button {
                display: flex;
            }
        }
    }
}