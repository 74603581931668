@import 'assets/scss/config/_variables';

#contact-form {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;

    .form-header {
        height: $contact-form-header-height;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        padding: 1rem;

        .icon {
            height: 60px;
            width: 60px;
            background-color: $secondary-color;
            border-radius: 7px;
            margin-right: 14px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 2.4em;
        }

        p {
            font-size: 0.75em;
        }
    }

    .form-content {
        padding: 2rem 1rem;
    }

    .column {
        position: relative;
    }

    .input {
        background-color: #f7f7f7;
        border: none;
        box-shadow: none;
    }

    .form-error {
        color: red;
        font-size: .8em;
        position: absolute;
        right: 1rem;
        bottom: 0.75rem;
    }

    button[type="submit"] {
        background-color: $secondary-color;
        color: #fff;
    }
}


@media(min-width: 768px) {
    #contact-form {
        .form-header {
            padding: 1.5rem;

            p {
                font-size: 1em;
            }
        }

        .form-content {
            padding: 2.5rem 1.5rem;
        }
    }
}

@media(min-width: 1024px) {
    #contact-form {
        box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);

        .form-header {
            p {
                font-size: 1.3em;
            }
        }
    }
}

@media(min-width: 1216px) {
    #contact-form {
        .form-header {
            padding: 2.4rem;
        }

        .form-content {
            padding: 3rem 2.4rem;
        }
    }
}