@import 'assets/scss/config/_variables.scss';

#missions {
    padding: 6rem 0.75rem;

    .section-title {
        color: $primary-color;
    }

    .button {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 1.3em;
        font-weight: 700;
        color: $primary-color;
        margin-bottom: 14px;
    }

    .mission {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .icon {
            width: 92px;
            height: 92px;
            border-radius: 46px;
            border: 1px solid #eee;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: $secondary-color;
                font-size: 3em;
            }
        }

        .text {
            padding-left: 30px;
            font-size: .9em;
        }
    }
}

@media(min-width: 1024px) {
    #missions {
        .button {
            margin-bottom: 0;
        }
    }
}