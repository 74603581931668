@import 'assets/scss/config/_variables.scss';

#callout {
    background: linear-gradient(rgba(darken($primary-color, 9%), 0.7), rgba(darken($primary-color, 9%), 0.7)), url(/assets/images/oildrop-booking.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 43%;
    padding: 6rem 0.75rem;
    color: #fff;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .byline {
        max-width: 640px;
        text-align: center;
    }

    .button {
        margin-top: 50px;
    }
}