#booking-stepper {
    .mobile-title {
        font-size: 1.2em;
        font-weight: 700;
        border-bottom: 2px solid whitesmoke;
        padding-bottom: 0.75rem;
        margin-bottom: 1.25rem;
    }    
}

@media(min-width: 768px) {
    #booking-stepper {
        .mobile-title {
            display: none;
        }
    }
}