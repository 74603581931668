@import 'assets/scss/config/_variables.scss';

#gallery {
    background-color: $primary-color;

    .image {
        overflow: hidden;
        height: 360px;
        width: 100%;
        display: flex;
        align-items: center;

        // &.two-col {
        //     width: 960px;
        // }

        &.two-row {
            height: 720px;

            img {
                min-height: 100%;
            }
        }
        
        img {
            transition: transform .2s;
            min-height: 100%;
        }

        &:hover img {
            transition: transform .2s;
            transform: scale(1.125);
        }
    }
}