@import 'assets/scss/config/_variables.scss';

#testimonials {
    padding: 100px 0;
    background-color: $primary-color;
    color: #fff;

    &.is-light {
        background-color: #f7f7f7;
        color: #4a4a4a;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .portrait {
        width: 110px;
        height: 110px;
        margin-bottom: 60px;
        
        img {
            width: 100%;
            border-radius: 55px;
            border: 3px solid $secondary-color;
        }
    }

    .testimonial {
        max-width: 640px;
        text-align: center;
        margin-bottom: 40px;
    }

    h3.name {
        font-size: 1.1em;
        font-weight: 700;
        color: $secondary-color;
    }

    .job-title {
        font-size: .7em;
        font-weight: 600;
    }
}