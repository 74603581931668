#booking-location-form {
    .map {
        height: 62.5vh; //92vw
    }

    .mapboxgl-ctrl-geocoder {
        max-width: 100%;
        width: 88vw; //45.2vw
    }
}

@media(min-width: 768px) {
    #booking-location-form {
        .mapboxgl-ctrl-geocoder {
            width: 94vw;
        }
    }
}

@media(min-width: 1024px) {
    #booking-location-form {
        .mapboxgl-ctrl-geocoder {
            width: 60vw;
        }
    }
}

@media(min-width: 1216px) {
    #booking-location-form {
        .mapboxgl-ctrl-geocoder {
            width: 45.2vw; //24vw
        }
    }
}