@import 'assets/scss/config/_variables';

#service-separator {
    height: calc(100vw);
    background-color: $primary-color;
    background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .65)), url($service-separator-image);
    background-position: 20% 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrap {
        color: #fff;
        font-size: 2.4em;
        font-weight: 700;
        max-width: 800px;
        text-align: center;
    }
}

@media(min-width: 1024px) {
    #service-separator {
        height: calc(46vw);
        background-position: center;
    }
}