@import 'assets/scss/config/_variables';

#contact {
    background-color: #f7f7f7;

    .columns {
        margin-left: 0;
        margin-right: 0;

        .column {
            padding: 0.75rem 0;
        }
    }

    #social-icons {
        position: relative;
        top: -4rem;
        font-size: 1.25em;

        a {
            color: #fff;

            &:hover {
                color: $secondary-color;
            }
        }
    }

    #branch {
        display: none;
    }

    #contact-form {
        position: relative;
        top: -4rem;
    }
}

@media(min-width: 1024px) {
    #contact {
        .columns {
            margin-left: -0.75rem;
            margin-right: -0.75rem;

            .column {
                padding: 0.75rem;
            }
        }

        #branch {
            display: block;
        }

        #contact-form {
            top: -$contact-form-header-height;
        }
    }
}