#booking-confirmation {
    padding: 0.8rem 0;

    .title {
        margin-bottom: 1.25rem;
    }

    p {
        padding-bottom: 1.5rem;
    }

    ul {
        margin-bottom: 2rem;
    }

    li {
        display: flex;
        align-content: center;
        margin-bottom: 0.75rem;

        svg {
            font-size: 1.4em;
            color: #ccc;
            margin: 0 0.75rem 0 0.25rem;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }
}