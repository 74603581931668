@import 'assets/scss/config/_variables';

#recent-post {
    margin-bottom: 3rem;

    &:last-child {
        margin-bottom: 0;
    }
    
    .image {
        margin-bottom: 25px;
        height: calc(28vh);
        overflow: hidden;

        img {
            min-height: 100%;
            object-fit: cover;
        }
    }

    .date {
        font-size: .8em;
        font-weight: 600;
        color: $secondary-color;
        margin-bottom: 0.5rem;
    }

    .title {
        font-size: 1.3em;
        font-weight: 700;
        color: $primary-color;
        margin-bottom: 14px;
    }   
}

@media(min-width: 1024px) {
    #recent-post {
        margin-bottom: 0;
    }
}