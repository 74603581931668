@import 'assets/scss/config/_variables';

#banner {
    background-color: $primary-color;
    background: linear-gradient($primary-color, darken($primary-color, 5%));
    padding: 10rem 0.75rem;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .section-title {
        max-width: 550px;
        color: #fff;
        line-height: 1.3em;
        text-align: center;
        margin-bottom: 2rem;
    }

    .byline {
        max-width: 640px;
        color: #fff;
        font-size: 1.2em;
        text-align: center;
    }
}