#company-values {
    padding: 7rem 0.75rem;

    .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 2rem;
        margin-bottom: 4rem;
    }

    img {
        max-width: 160px;
        margin-bottom: 2.5rem;
    }

    p {
        text-align: center;
        white-space: break-spaces
    }
}

@media(min-width: 1024px) {
    #company-values {
        .column {
            margin-bottom: 0;
        }
    }
}