h1, h2, h3, h4, h5, h6 {
    font-family: 'Merriweather', serif;
}

p {
    font-family: 'Open Sans', sans-serif;
}

a {
    color: $secondary-color;
    transition: color .5s;

    &:hover {
        color: lighten($secondary-color, 10%);
    }
}

.section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.center {
        align-items: center;
        text-align: center;
    }

    &:after {
        display: inline-block;
        content: " ";
        background-image: url(/assets/svg/dots.svg);
        background-size: contain;
        margin-top: 10px;
    }
}

h1.section-title {
    font-size: 2.6em;
    font-weight: 700;
    margin-bottom: 30px;

    &:after {
        height: 8px;
        width: 50px;
    }
}

h2.section-title {
    font-size: 2.4em;
    font-weight: 700;
    margin-bottom: 20px;

    &:after {
        height: 5px;
        width: 31px;
    }
}

.button.is-oildrop {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: #fff;

    &.sm-text {
        font-size: .7em;
        font-weight: 700;
        text-transform: uppercase;
        height: auto;
        padding: 14px 36px;
    }

    &:hover {
        color: #fff;
        background-color: darken($secondary-color, 5%);
        border-color: darken($secondary-color, 5%);
    }

    &:active {
        color: #fff;
        background-color: darken($secondary-color, 7%);
        border-color: darken($secondary-color, 7%);
    }

    &:focus {
        color: #fff;
        border-color: $secondary-color;

        &:not(:active) {
            box-shadow: 0 0 0 0.125em rgba($secondary-color, 0.25)
        }
    }

    &[disabled] {
        background-color: $secondary-color;
        border-color: $secondary-color;
    }
}

.button.btn {
    background-color: $secondary-color;
    border-color: $secondary-color;
    text-transform: uppercase;
    font-size: .7em;
    font-weight: 700;
    color: #fff;
    height: auto;
    padding: 14px 36px;

    &:hover {
        border-color: darken($secondary-color, 5%);
        color: #fff;
        background-color: darken($secondary-color, 5%);
    }

    &:focus {
        border-color: lighten($secondary-color, 5%);
        color: #fff;
        background-color: lighten($secondary-color, 5%);

        &:not(:active) {
            box-shadow: none;
        }
    }

    &:active {
        color:#fff;
    }
}

@media(min-width: 1024px) {
    h1.section-title {
        font-size: 4em;
    }
}