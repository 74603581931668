#about-oildrop {
    background-color: #f7f7f7;
    padding: 6rem 0.75rem;
    text-align: center;

    .container.limited {
        max-width: 550px;
    }    

    .section-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 0.75rem;
    }
}