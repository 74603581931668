#booking-stepper-buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .validation-errors {
        position: absolute;
        left: 0;

        li {
            font-size: 0.8em;
            color: red;
        }
    }
}